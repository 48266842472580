import { UserInfo } from "@shared/models";
import { create } from "zustand";

import { AthleteProfile } from "../../../shared/athleteModels";

interface AppState {
  userInfo?: UserInfo;
  setUserInfo: (val: UserInfo) => void;
  isIntern: boolean;
  setIsIntern: (val: boolean) => void;
  isOnboarding: boolean;
  setIsOnboarding: (val: boolean) => void;
  athleteResumeData: AthleteProfile | null;
  setAthleteResumeData: (val: AthleteProfile | null) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  setUserInfo: (val) => set({ userInfo: val }),
  isIntern: false,
  setIsIntern: (val: boolean) => set({ isIntern: val }),
  isOnboarding: false,
  setIsOnboarding: (val: boolean) => set({ isOnboarding: val }),
  athleteResumeData: null,
  setAthleteResumeData: (val: AthleteProfile | null) => set({ athleteResumeData: val }),
}));
