// import { switchAnatomy } from "@chakra-ui/anatomy";
import { extendTheme } from "@chakra-ui/react";

import { config } from "./config";

// const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const theme = extendTheme({
  colors: {
    brand: {
      50: "#f2effc",
      100: "#d9d1f5",
      200: "#bfb2ee",
      300: "#a693e7",
      400: "#8d74e0",
      500: "#604abb", // Base color
      600: "#55429e",
      700: "#463581",
      800: "#362864",
      900: "#271b47",
      primary: "#604ABB",
      secondary: "#00ff00",
      accent: "#FF686B",
      cta: "#0083E2",
      background: "#F7F7F7",
      backgroundHover: "#D5CCD7",
    },
  },
  textStyles: {
    body: {
      fontFamily: "Inter Variable",
      fontWeight: "400",
      color: "#291711",
      fontSize: ".90rem",
      lineHeight: "tall",
    },
  },
  fonts: {
    heading: "Inter Variable, sans-serif",
    body: "Inter Variable, sans-serif",
  },
  components: {
    Link: {
      baseStyle: {
        color: "brand.primary",
        _hover: {
          textDecoration: "underline",
        },
      },
      variants: {
        customVariant: {
          color: "green.600",
          fontWeight: "bold",
        },
      },
    },
    Button: {
      variants: {
        cta: {
          bg: "brand.cta",
          _hover: { bg: "#026DBD" },
          color: "white",
          fontWeight: "600",
        },
        secondary: {
          color: "gray.700",
          _hover: { bg: "gray.100" },
          bg: "white",
          border: "1px",
          fontWeight: "600",
          borderColor: "gray.400",
        },
        danger: {
          bg: "#D20404",
          _hover: { bg: "#B50303" },
          color: "white",
          fontWeight: "600",
        },
      },
    },
    Container: {
      sizes: {
        sm: {
          maxWidth: "640px",
        },
        md: {
          maxWidth: "960px",
        },
        lg: {
          maxWidth: "1280px",
        },
        xl: {
          maxWidth: "1600px",
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.300",
            borderWidth: "1px",
            _focus: {
              borderColor: "purple.500",
              boxShadow: "0 0 0 1px #3182ce",
            },
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.300",
            borderWidth: "1px",
            _focus: {
              borderColor: "purple.500",
              boxShadow: "0 0 0 1px #3182ce",
            },
            _invalid: {
              borderColor: "red.500",
              _focus: {
                borderColor: "red.500",
                boxShadow: "0 0 0 2px #BB1313",
              },
            },
          },
        },
      },
    },
  },
  config,
});

export const ThemeColors = {
  brand: {
    primary: "brand.primary",
    primaryValue: theme.colors.brand.primary,
    cta: "brand.cta",
    backgroundColor: "brand.backgroundColor",
  },
};
