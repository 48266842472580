import { useState } from "react";

import {
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  Button,
} from "@chakra-ui/react";

type SaveHighlightModalProps = {
  isOpen: boolean;
  onClose: () => void;
  highlights: string[];
  onSave?: (bullets: string[]) => void;
  isSaving?: boolean;
};

export function SaveHighlightModal({ isOpen, onClose, highlights, onSave, isSaving }: SaveHighlightModalProps) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(highlights);

  const handleCheckboxChange = (value: string) => {
    if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== value));
    } else {
      if (selectedCheckboxes.length < 3) {
        setSelectedCheckboxes([...selectedCheckboxes, value]);
      }
    }
  };

  const handleSave = () => {
    onSave?.(selectedCheckboxes);
  };

  const handleClose = () => {
    if (isSaving) {
      return;
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Highlights
          <Text fontSize="sm" color="gray.500">
            Select which highlights you want to save
          </Text>
        </ModalHeader>
        <ModalBody>
          <Table>
            <Tbody>
              {highlights?.map?.((point, index) => (
                <Tr key={"save-highligh-" + index}>
                  <Td width="30px" px={0}>
                    <Checkbox
                      key={index}
                      isChecked={selectedCheckboxes.includes(point)}
                      onChange={() => handleCheckboxChange(point)}
                      disabled={(selectedCheckboxes.length === 3 && !selectedCheckboxes.includes(point)) || isSaving}
                    />
                  </Td>
                  <Td pl={0}>
                    <Text>{point}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button variant={"secondary"} onClick={handleClose} mr={4} isLoading={isSaving}>
            Cancel
          </Button>
          <Button colorScheme="purple" onClick={handleSave} isLoading={isSaving}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
