import { ReactNode } from "react";

import { Icon } from "@chakra-ui/icons";
import { routeAccess, RoutePath, UserRole, AthleteRole } from "@shared/models";
import { BsPersonFill, BsPersonFillGear } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { FaBurst } from "react-icons/fa6";
import { IoMdHelpCircle, IoMdSchool } from "react-icons/io";

import { AppConfig } from "../services/app-config-service";

const allRoles = [UserRole.ATHLETE, UserRole.RECRUITER, UserRole.ADMIN, UserRole.UNIVERSITY, AthleteRole.INTERN];

export interface MenuLink {
  label: string;
  path: RoutePath;
  target?: string;
  icon?: ReactNode;
  role: (UserRole | AthleteRole)[];
}

export const TopMenuLinks: MenuLink[] = [
  { label: "Our Team", path: RoutePath.OUR_TEAM, target: "_blank", role: allRoles },
];

export let SideMenuLinks: MenuLink[] = [
  {
    label: "My Dashboard",
    path: RoutePath.ATHLETE_DASHBOARD,
    icon: <Icon as={BsPersonFillGear} />,
    role: routeAccess[RoutePath.ATHLETE_DASHBOARD],
  },
  {
    label: "My Profile",
    path: RoutePath.ATHLETE_PROFILE,
    icon: <Icon as={BsPersonFill} />,
    role: routeAccess[RoutePath.ATHLETE_PROFILE],
  },
  // { label: "AI Coach", path: RoutePath.COACH, icon: <Icon as={IoIosChatboxes} />, role: routeAccess[RoutePath.COACH] },
  { label: "Internship", path: RoutePath.INTERN, icon: <Icon as={IoMdSchool} />, role: routeAccess[RoutePath.INTERN] },
  {
    label: "Dashboard",
    path: RoutePath.UNIVERSITY_DASHBOARD,
    icon: <Icon as={IoMdSchool} />,
    role: routeAccess[RoutePath.UNIVERSITY_DASHBOARD],
  },
  // { label: "Find Employers", path: RoutePath.FIND_EMPLOYERS, icon: <Icon as={FaSearch} />, role: routeAccess[RoutePath.FIND_EMPLOYERS] },
  // { label: "Dashboard ", path: RoutePath.EMPLOYER_DASHBOARD, icon: <Icon as={BsBuildingFillGear} />, role: routeAccess[RoutePath.EMPLOYER_DASHBOARD] },
  // { label: "Profile", path: RoutePath.EMPLOYER_PROFILE, icon: <Icon as={BsBuildingFill} />, role: routeAccess[RoutePath.EMPLOYER_PROFILE] },
  {
    label: "Find Athletes",
    path: RoutePath.FIND_ATHLETES,
    icon: <Icon as={FaSearch} />,
    role: routeAccess[RoutePath.FIND_ATHLETES],
  },
  { label: "Admin", path: RoutePath.ADMIN, icon: <Icon as={FaBurst} />, role: routeAccess[RoutePath.ADMIN] },
  {
    label: "Feedback",
    path: RoutePath.FEEDBACK,
    icon: <Icon as={IoMdHelpCircle} />,
    role: routeAccess[RoutePath.FEEDBACK],
  },
];

// TODO: Remove this once we go live with profile-driven conversations
if (AppConfig.shouldHideWipFeatures) {
  SideMenuLinks = SideMenuLinks.filter((link) => link.label !== "Profile");
}
