import { useState, useEffect, useRef } from "react";

import { ColDef } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Box, Flex, Heading, Button, Text, Divider, Hide, Show, Center, useBreakpointValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import { simpleDebounce } from "../../utils";

interface UniversitiesTabProps {
  isActive: boolean;
}

interface University {
  id: string;
  name: string;
  primaryContact: string;
  students: number;
  activeStudents: number;
  location: string;
  size: string;
}

const pageInfoHeight = 0.2;

export const UniversitiesTab = ({ isActive }: UniversitiesTabProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [universities] = useState<University[]>(initialUniversities);
  const [pageHeight, setPageHeight] = useState(0);
  const [selectedUniversity, setSelectedUniversity] = useState<University | null>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<AgGridReact>(null);

  useEffect(() => {
    const debouncedResize = simpleDebounce(() => {
      resizeTable();
      if (pageRef.current) {
        const innerHeight = window.innerHeight;
        const rect = pageRef.current.getBoundingClientRect();
        setPageHeight(innerHeight - (rect.top + 50));
      }
    }, 150);

    window.addEventListener("resize", debouncedResize);

    if (tableRef.current) {
      debouncedResize();
    }

    return () => {
      window.removeEventListener("resize", debouncedResize);
      debouncedResize.cancel(); // Cancel any pending debounce calls
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      resizeTable();
    }
  }, [isActive]);

  const resizeTable = () => {
    if (tableRef.current && tableRef.current.api) {
      tableRef.current.api.sizeColumnsToFit();
    }
    if (pageRef.current) {
      const innerHeight = window.innerHeight;
      const rect = pageRef.current.getBoundingClientRect();
      setPageHeight(innerHeight - (rect.top + 50));
    }
  };

  const columnDefs: ColDef[] = [
    { headerName: "University Name", field: "name", filter: true },
    { headerName: "Primary Contact", field: "primaryContact" },
    { headerName: "Total Students", field: "students" },
    { headerName: "Active Students", field: "activeStudents" },
    { headerName: "Location", field: "location" },
    { headerName: "Size", field: "size" },
  ];

  if (isMobile) {
    return (
      <Center height="50vh">
        <Text fontSize="lg" fontWeight="medium" textAlign="center">
          We can't wait to provide an awesome mobile experience, but we aren't quite there yet. Please use a desktop to
          view this page.
        </Text>
      </Center>
    );
  }

  return (
    <Flex direction="column" h={pageHeight} ref={pageRef}>
      <Flex
        direction={{ base: "column-reverse", md: "row" }}
        p={4}
        h={[pageHeight - 10, `${pageInfoHeight * 100}%`]}
        justify="space-between"
        gap={4}
      >
        <Flex flex={1} flexDirection="column">
          <Box
            w="100%"
            h="100%"
            display="flex"
            justifyContent={["center", "flex-start"]}
            alignItems={["center", "flex-start"]}
          >
            <Button colorScheme="teal">Add New University</Button>
          </Box>
        </Flex>
        <Flex flex={[2, 1]} flexDirection="column">
          {selectedUniversity ? (
            <>
              <Heading size="md">{selectedUniversity.name}</Heading>
              <p>{selectedUniversity.primaryContact}</p>
              <Divider my={4} />
              <Text>Here we can see more details about the university.</Text>
            </>
          ) : (
            <>
              <Heading size="md">Overview</Heading>
              <p>Here you can manage and view all the universities.</p>
            </>
          )}
        </Flex>
        <Hide above="sm">
          <Box w="100%" p={4}>
            <Select
              options={universities.map((university: University) => ({
                label: university.name,
                value: university.id,
              }))}
              placeholder="Select a university"
              onChange={(e) => {
                setSelectedUniversity(universities.find((university) => university.id === e?.value) || null);
              }}
            />
          </Box>
        </Hide>
      </Flex>
      <Show above="sm">
        <Flex h={`${(1 - pageInfoHeight) * 100}%`}>
          <div className="ag-theme-quartz" style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={tableRef}
              rowData={universities}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
              onRowClicked={(event) => {
                setSelectedUniversity(universities.find((university) => university.id === event.data.id) || null);
              }}
            />
          </div>
        </Flex>
        <Box h="10px" />
      </Show>
    </Flex>
  );
};

const initialUniversities: University[] = [
  {
    id: "1",
    name: "Tech Innovators University",
    primaryContact: "John Doe",
    students: 5000,
    activeStudents: 3000,
    location: "San Francisco, CA",
    size: "200-500",
  },
  {
    id: "2",
    name: "Health Solutions University",
    primaryContact: "Jane Smith",
    students: 3000,
    activeStudents: 2000,
    location: "New York, NY",
    size: "500-1000",
  },
  {
    id: "3",
    name: "Green Energy University",
    primaryContact: "Alice Johnson",
    students: 8000,
    activeStudents: 5000,
    location: "Austin, TX",
    size: "100-200",
  },
  {
    id: "4",
    name: "EduTech University",
    primaryContact: "Robert Brown",
    students: 2000,
    activeStudents: 1000,
    location: "Boston, MA",
    size: "50-100",
  },
  {
    id: "5",
    name: "Finance Gurus University",
    primaryContact: "Emily Davis",
    students: 6000,
    activeStudents: 4000,
    location: "Chicago, IL",
    size: "1000-5000",
  },
  {
    id: "6",
    name: "Retail Experts University",
    primaryContact: "Michael Wilson",
    students: 4000,
    activeStudents: 3000,
    location: "Los Angeles, CA",
    size: "500-1000",
  },
  {
    id: "7",
    name: "Construction Masters University",
    primaryContact: "Sarah Lee",
    students: 7000,
    activeStudents: 5000,
    location: "Denver, CO",
    size: "200-500",
  },
  {
    id: "8",
    name: "Marketing Pros University",
    primaryContact: "David Martinez",
    students: 1000,
    activeStudents: 1000,
    location: "Miami, FL",
    size: "50-100",
  },
  {
    id: "9",
    name: "Logistics Solutions University",
    primaryContact: "Laura Garcia",
    students: 9000,
    activeStudents: 7000,
    location: "Seattle, WA",
    size: "100-200",
  },
  {
    id: "10",
    name: "Consulting Experts University",
    primaryContact: "James Anderson",
    students: 10000,
    activeStudents: 8000,
    location: "Washington, D.C.",
    size: "500-1000",
  },
];
