import { useState } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getPositionBySportId, getSports } from "../services/hca-api";
import { getAllUniversities } from "../services/university-api";

export function useAthleteProfileBuilderData() {
  const queryClient = useQueryClient();
  const [primarySportId, setPrimarySportId] = useState<number | null>(null);

  const { data: universityList, isLoading: isLoadingUniversities } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
  });

  const { data: sportList, isLoading: isLoadingSports } = useQuery({
    queryKey: ["sports"],
    queryFn: getSports,
  });

  const { data: positions, isLoading: isLoadingPositions } = useQuery({
    queryKey: ["positions", primarySportId],
    queryFn: () => {
      if (!primarySportId) return [];
      return getPositionBySportId(primarySportId);
    },
    enabled: !!primarySportId,
  });

  const handlePrimarySportChange = (sportId: number) => {
    if (sportId === primarySportId) return;
    setPrimarySportId(sportId);
    queryClient.invalidateQueries({ queryKey: ["positions"] });
  };

  return {
    universityList,
    sportList,
    handlePrimarySportChange,
    positions,
    isLoading: isLoadingUniversities || isLoadingSports,
    isLoadingPositions: isLoadingPositions,
  };
}
