import { useEffect, useRef, useState } from "react";

import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import {
  BasicTextStyleButton,
  CreateLinkButton,
  FormattingToolbar,
  FormattingToolbarController,
  NestBlockButton,
  UnnestBlockButton,
  useCreateBlockNote,
} from "@blocknote/react";
import { CheckIcon, EditIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { BsBuildingFill } from "react-icons/bs";
import { FaTools, FaRunning } from "react-icons/fa";
import { FaHandshakeSimple, FaBrain, FaCertificate } from "react-icons/fa6";
import { IoLibrarySharp, IoTrophySharp } from "react-icons/io5";

import { resumeContentConfig } from "../../../../../shared/resumeContentConfig";
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { useAppStore } from "../../app-store";
import { DashboardProfileSection } from "../../pages/athlete/dashboard/AthleteDashboardPage";

interface AthleteProfileContentCardProps {
  section: DashboardProfileSection;
  onEdit?: (section: DashboardProfileSection) => void;
}

export const icons = {
  IoLibrarySharp: IoLibrarySharp,
  IoTrophySharp: IoTrophySharp,
  BsBuildingFill: BsBuildingFill,
  FaHandshakeSimple: FaHandshakeSimple,
  FaTools: FaTools,
  FaBrain: FaBrain,
  FaRunning: FaRunning,
  FaCertificate: FaCertificate,
};

export const AthleteProfileContentCard = ({ section, onEdit }: AthleteProfileContentCardProps) => {
  const [isOnboarding] = useAppStore((state) => [state.isOnboarding]);
  const editor = useCreateBlockNote({ trailingBlock: false });
  const [isBlank, setIsBlank] = useState(true);
  const [inEditMode, setInEditMode] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState(0);
  const [originalContent, setOriginalContent] = useState<string>("");

  useEffect(() => {
    const loadContent = async (editor: BlockNoteEditor, content: string) => {
      const markdownBlocks = await editor.tryParseMarkdownToBlocks(content);
      editor.replaceBlocks(editor.document, markdownBlocks);
    };

    if (section?.content !== "") {
      loadContent(editor, section.content);
      setIsBlank(false);
      if (section.isNew && isOnboarding) {
        setInEditMode(true);
      }
    } else {
      loadContent(editor, "");
      setIsBlank(true);
      setInEditMode(false);
    }
  }, [section, editor, isOnboarding]);

  const StyledBlockNoteView = styled.div`
    .bn-editor {
      padding: 8px;
      padding-left: 16px;
    }
  `;

  const handleEditClick = async () => {
    setCardHeight(cardRef.current?.clientHeight || 200);
    if (inEditMode) {
      const markdownContent = await editor.blocksToMarkdownLossy();
      onEdit && onEdit({ ...section, content: markdownContent, isNew: false });
    } else {
      const currentContent = await editor.blocksToMarkdownLossy();
      setOriginalContent(currentContent);
    }
    setInEditMode(!inEditMode);
  };

  const handleCancelEdit = async () => {
    const markdownBlocks = await editor.tryParseMarkdownToBlocks(originalContent);

    editor.replaceBlocks(editor.document, originalContent ? markdownBlocks : []);
    onEdit && onEdit({ ...section, content: originalContent, isNew: false });
    setInEditMode(false);
  };

  const mustAcceptContent = section.isNew && isOnboarding;
  const borderColor = mustAcceptContent ? "#EDB02B" : inEditMode ? "purple.500" : "transparent";

  const UpdatingCard = () => {
    return (
      <Card
        h={cardHeight || "200px"}
        w="100%"
        bg="white"
        size="md"
        borderRadius="lg"
        borderColor="transparent"
        borderWidth={2}
      >
        <CardHeader>
          <Heading size="md">Updating {resumeContentConfig[section.type || ""].title}...</Heading>
        </CardHeader>
        <CardBody>
          <Center h="100%" w="100%">
            <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
          </Center>
        </CardBody>
      </Card>
    );
  };

  if (section.isUpdating) {
    return <UpdatingCard />;
  }

  return (
    <Card
      ref={cardRef}
      w="100%"
      h="100%"
      bg={isBlank ? "gray.200" : "white"}
      borderColor={isBlank ? "transparent" : borderColor}
      borderWidth={2}
      size={"md"}
      borderRadius="lg"
    >
      <CardHeader>
        <HStack justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap={2}>
            {resumeContentConfig[section.type].icon && (
              <Icon as={icons[resumeContentConfig[section.type].icon as keyof typeof icons]} fontSize="20px" />
            )}
            <Heading size="md">{resumeContentConfig[section.type || ""].title || ""}</Heading>
          </Flex>
          {onEdit && (
            <HStack>
              {inEditMode ? (
                <>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    aria-label="Cancel"
                    icon={<CloseIcon fontSize="17px" />}
                    _hover={{ bg: "red.400", color: "white" }}
                    onClick={handleCancelEdit}
                  />
                  <IconButton
                    variant="ghost"
                    size="sm"
                    aria-label="Save"
                    icon={<CheckIcon fontSize="20px" />}
                    _hover={{ bg: borderColor ?? "brand.400", color: "white" }}
                    onClick={handleEditClick}
                  />
                </>
              ) : (
                <IconButton
                  variant={isBlank ? "filled" : "ghost"}
                  bg={isBlank ? "white" : "transparent"}
                  size="sm"
                  aria-label="Edit"
                  icon={<EditIcon fontSize="20px" />}
                  _hover={{ bg: "brand.400", color: "white" }}
                  onClick={handleEditClick}
                />
              )}
            </HStack>
          )}
        </HStack>
        {isBlank && (
          <Text fontSize="12" color="slate.500" mt={2} fontStyle="italic">
            {resumeContentConfig[section.type || ""].instructions}
          </Text>
        )}
      </CardHeader>
      <CardBody pt={0}>
        <StyledBlockNoteView>
          <BlockNoteView
            editor={editor}
            editable={inEditMode}
            theme="light"
            sideMenu={false}
            linkToolbar={false}
            filePanel={false}
            slashMenu={false}
            tableHandles={false}
            formattingToolbar={false}
          >
            <FormattingToolbarController
              formattingToolbar={() => (
                <FormattingToolbar>
                  <BasicTextStyleButton basicTextStyle="bold" key="boldStyleButton" />
                  <BasicTextStyleButton basicTextStyle="italic" key="italicStyleButton" />
                  <NestBlockButton key={"nestBlockButton"} />
                  <UnnestBlockButton key={"unnestBlockButton"} />
                  <CreateLinkButton key={"createLinkButton"} />
                </FormattingToolbar>
              )}
            />
          </BlockNoteView>
        </StyledBlockNoteView>
      </CardBody>
    </Card>
  );
};
