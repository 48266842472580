import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
const isIOS = browser.getOSName() === "iOS";
const isMobileIOS = isIOS && isTouchDevice;

export const BrowserService = {
  isTouchDevice,
  isMobileIOS,
};
