import { Grid, GridItem } from "@chakra-ui/react";

import AthleteProfileCard from "./AthleteProfileCard";
import { AthleteProfile } from "../../../../../shared/athleteModels";
import { AthleteProfileContentCard } from "../../components/cards/AthleteProfileContentCard";
import { Page } from "../../components/Page";
import { useProfileSections } from "../../hooks/useProfileSections";

interface AthleteProfileProps {
  athleteProfile: AthleteProfile;
  profileImage: { url: string } | null;
}

export function AthletePage({ athleteProfile, profileImage }: AthleteProfileProps) {
  const { groupFlexSectionsIntoRows, determineColSpan } = useProfileSections();
  const rowGroupedSections = groupFlexSectionsIntoRows(athleteProfile.sections);

  const summarySection = athleteProfile.sections.find((section) => section.type === "summary");
  const workExperienceSection = athleteProfile.sections.find((section) => section.type === "work_experience");

  const topRowFlexSections = rowGroupedSections?.[0] ?? [];
  const theRestFlexSections = rowGroupedSections?.slice(1) ?? [];

  return (
    <Page>
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} w="100%">
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <AthleteProfileCard athleteProfile={athleteProfile} profileImage={profileImage} />
        </GridItem>

        {summarySection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={summarySection} />
          </GridItem>
        )}

        {topRowFlexSections.map((section) => (
          <GridItem
            key={section.id}
            colSpan={{
              base: 1,
              md: determineColSpan(section),
            }}
          >
            <AthleteProfileContentCard section={section} />
          </GridItem>
        ))}

        {workExperienceSection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={workExperienceSection} />
          </GridItem>
        )}

        {theRestFlexSections.map((row) =>
          row.map((section) => (
            <GridItem
              key={section.id}
              colSpan={{
                base: 1,
                md: row.length === 1 ? 3 : determineColSpan(section),
              }}
            >
              <AthleteProfileContentCard section={section} />
            </GridItem>
          )),
        )}
      </Grid>
    </Page>
  );
}
