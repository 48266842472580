import { useCallback, useState } from "react";

import { VStack, Text, useToast, Box } from "@chakra-ui/react";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";

interface UploadedFile {
  file: File;
  status: "pending" | "complete" | "error";
  errorMessage?: string;
}

interface GenericFileUploadProps {
  onFileUpload: (file: File) => Promise<void>;
  acceptedFileTypes?: Record<string, string[]>;
  isUploading?: boolean;
  label?: string;
}

export const GenericFileUpload: React.FC<GenericFileUploadProps> = ({
  onFileUpload,
  acceptedFileTypes = {
    "application/pdf": [".pdf"],
  },
  label = "Drop your resume here or click to select",
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const toast = useToast();

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        toast({
          title: "Invalid file type",
          description: "Please upload a PDF file",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const file = acceptedFiles[0]; // Only handle single file

      try {
        setUploadedFiles((prev) => [...prev, { file, status: "pending" }]);
        await onFileUpload(file);
        setUploadedFiles((prev) => prev.map((f) => (f.file.name === file.name ? { ...f, status: "complete" } : f)));
      } catch (error) {
        setUploadedFiles((prev) =>
          prev.map((f) => (f.file.name === file.name ? { ...f, status: "error", errorMessage: "Upload failed" } : f)),
        );
        toast({
          title: "Error uploading file",
          description: "There was an issue uploading your file",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    },
    [onFileUpload, toast],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false,
    validator: (file) => {
      if (!file.type || file.type !== "application/pdf") {
        return {
          code: "invalid-file-type",
          message: "Only PDF files are allowed",
        };
      }
      return null;
    },
  });

  return (
    <VStack spacing={4} width="100%" bgColor="white" p={2} borderRadius="lg">
      <Box
        {...getRootProps()}
        width="100%"
        p={6}
        border="3px dashed"
        bgColor={isDragActive ? "brand.500" : "brand.100"}
        borderColor={isDragActive ? "brand.700" : "brand.500"}
        borderRadius="lg"
        textAlign="center"
        cursor="pointer"
        transition="all 0.2s"
        _hover={{ borderColor: "brand.500" }}
      >
        <input {...getInputProps()} />
        <Text fontSize="md">{label}</Text>
      </Box>

      {uploadedFiles.map((file, index) => (
        <Text key={index} color={file.status === "error" ? "red.500" : "green.500"}>
          {file.file.name} - {file.status}
          {file.errorMessage && ` - ${file.errorMessage}`}
        </Text>
      ))}
    </VStack>
  );
};
