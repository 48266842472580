import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { AthleteProfile } from "../../../../shared/athleteModels";
import {
  postAthleteContent,
  postAthleteInfo,
  postAthleteProfile,
  getAthleteInfo,
  getSpecificAthleteInfo,
  getAthleteProfile,
  getSpecificAthleteProfile,
  getAllAthleteInfo,
} from "../services/athlete-api";

export const useAthleteInfo = () => {
  return useQuery({
    queryKey: ["athleteInfo"],
    queryFn: () => getAthleteInfo(),
  });
};

export const useAllAthleteInfo = () => {
  return useQuery({
    queryKey: ["allAthleteInfo"],
    queryFn: () => getAllAthleteInfo(),
  });
};

export const useSpecificAthleteInfo = (userId: number) => {
  return useQuery({
    queryKey: ["athleteInfo", userId],
    queryFn: () => getSpecificAthleteInfo(userId),
  });
};

export const useAthleteProfile = (): UseQueryResult<AthleteProfile> => {
  return useQuery({
    queryKey: ["athleteProfile"],
    queryFn: () => getAthleteProfile(),
  });
};

export const useSpecificAthleteProfile = (userId: number) => {
  return useQuery({
    queryKey: ["athleteProfile", userId],
    queryFn: () => getSpecificAthleteProfile(userId),
  });
};

export const useAthleteInfoMutation = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: postAthleteInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteInfo"] });
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile details updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile details",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete info:", error);
    },
  });
};

export const useAthleteContentMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: postAthleteContent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile changes saved",
        status: "success",
        position: "top",
        duration: 3000,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete content:", error);
    },
  });
};

export const useAthleteProfileMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: postAthleteProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteInfo"] });
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete profile:", error);
    },
  });
};
