import { AthleteContentSection } from "./athleteModels";

export interface SectionConfig {
  title: string;
  key: string;
  icon?: string;
  size: "full" | "flex";
  emptySize: 1 | 2 | 3;
  instructions: string;
  priority: number | null;
  canBeMultiple: boolean;
}

export interface ResumeContentConfig {
  [key: string]: SectionConfig;
}

export const resumeContentConfig: ResumeContentConfig = {
  summary: {
    title: "Summary",
    key: "summary",
    size: "full",
    emptySize: 3,
    instructions:
      "Provide a brief overview of your athletic and academic achievements, career goals, and what makes you stand out.",
    priority: 1,
    canBeMultiple: false,
  },
  education: {
    title: "Education",
    key: "education",
    icon: "IoLibrarySharp",
    size: "flex",
    emptySize: 1,
    instructions: "List your academic institutions, degrees, majors, and relevant coursework.",
    priority: 2,
    canBeMultiple: true,
  },
  athletics: {
    title: "Athletic Experience",
    key: "athletics",
    icon: "FaRunning",
    size: "flex",
    emptySize: 2,
    instructions: "Highlight your athletic achievements, teams, positions, and leadership roles.",
    priority: 3,
    canBeMultiple: true,
  },
  work_experience: {
    title: "Work Experience",
    key: "work_experience",
    icon: "BsBuildingFill",
    size: "full",
    emptySize: 3,
    instructions: "Detail your work history, internships, and relevant professional experiences.",
    priority: 4,
    canBeMultiple: false,
  },
  skills: {
    title: "Skills",
    key: "skills",
    icon: "FaBrain",
    size: "flex",
    emptySize: 2,
    instructions: "List technical, soft, and transferable skills developed through athletics and academics.",
    priority: 5,
    canBeMultiple: true,
  },
  certifications: {
    title: "Certifications",
    key: "certifications",
    icon: "FaCertificate",
    size: "flex",
    emptySize: 2,
    instructions: "List any professional certifications or licenses you have earned.",
    priority: 5.5,
    canBeMultiple: true,
  },
  awards: {
    title: "Awards",
    key: "awards",
    icon: "IoTrophySharp",
    size: "flex",
    emptySize: 1,
    instructions: "Share academic honors, athletic awards, and other notable recognitions.",
    priority: 6,
    canBeMultiple: false,
  },
  projects: {
    title: "Projects",
    key: "projects",
    icon: "FaTools",
    size: "flex",
    emptySize: 1,
    instructions: "Showcase academic, personal or team projects that demonstrate your skills and initiative.",
    priority: 7,
    canBeMultiple: true,
  },
  volunteer_experience: {
    title: "Volunteering",
    key: "volunteer_experience",
    icon: "FaHandshakeSimple",
    size: "flex",
    emptySize: 2,
    instructions: "Highlight community service, charitable work and other volunteer activities.",
    priority: 8,
    canBeMultiple: true,
  },
  references: {
    title: "References",
    key: "references",
    size: "flex",
    emptySize: 2,
    instructions: "List professional and academic references who can speak to your abilities and character.",
    priority: 9,
    canBeMultiple: true,
  },
  hobbies_interests: {
    title: "Hobbies & Interests",
    key: "hobbies_interests",
    size: "flex",
    emptySize: 1,
    instructions: "Share activities and interests outside of athletics and academics that round out your profile.",
    priority: 10,
    canBeMultiple: true,
  },
};

export const emptySectionStarter: AthleteContentSection[] = Object.values(resumeContentConfig).map((section) => ({
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  type: section.key,
  content: "",
}));
