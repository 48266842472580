import { Spinner, Center } from "@chakra-ui/react";

export interface AwesomeHCASpinnerProps {
  fullScreen?: boolean;
}

export const AwesomeHCASpinner = ({ fullScreen = false }: AwesomeHCASpinnerProps) => {
  return (
    <Center height={fullScreen ? "100vh" : "100%"} width={fullScreen ? "90vw" : "100%"}>
      <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
    </Center>
  );
};
