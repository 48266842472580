import { useRef } from "react";

import {
  ButtonGroupProps,
  ButtonProps,
  IconButtonProps,
  ButtonGroup,
  IconButton,
  CircularProgress,
  CircularProgressProps,
} from "@chakra-ui/react";
import { GoUpload } from "react-icons/go";

interface UploadButtonWithProgressProps {
  buttonLabel: string;
  disabled?: boolean;
  onFileUploaded: (file: File) => void;
  progress?: number;
  fileSizeLimitInMB?: number;

  // Pass-through props
  includeImageCropper?: boolean;
  buttonGroupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
  circularProgressProps?: CircularProgressProps;
}

export const UploadButtonWithProgress = ({
  buttonLabel,
  disabled,
  onFileUploaded,
  progress,
  fileSizeLimitInMB = 50,

  buttonGroupProps,
  circularProgressProps,
  iconButtonProps,
}: UploadButtonWithProgressProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > fileSizeLimitInMB * 1024 * 1024) {
      alert("File size cannot exceed 50MB");
      return;
    }

    onFileUploaded(file);

    // Reset the input so that the same file can be uploaded again
    fileInputRef.current?.form?.reset();
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <ButtonGroup isAttached variant="secondary" marginY="auto" {...buttonGroupProps} isDisabled={disabled}>
        {progress && progress > 0 ? (
          <IconButton
            variant={"secondary"}
            aria-label="progress"
            icon={<CircularProgress value={progress} size={"30px"} {...circularProgressProps} />}
            marginY="auto"
            {...iconButtonProps}
          />
        ) : (
          <IconButton
            aria-label={buttonLabel}
            icon={<GoUpload />}
            {...iconButtonProps}
            onClick={() => handleButtonClick()}
          />
        )}
      </ButtonGroup>
      <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={handleFileChange} />
    </>
  );
};
