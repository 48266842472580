import { useLocation, useNavigate } from "react-router-dom";

const useUrlParameter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getSearchParams = () => new URLSearchParams(location.search);

  const updateUrl = (params: URLSearchParams) => {
    const queryString = params.toString();
    const url = `${location.pathname}${queryString ? `?${queryString}` : ""}`;
    navigate(url, { replace: true });
  };

  const setUrlParameter = (parameter: string, value: string) => {
    const params = getSearchParams();
    params.set(parameter, value);
    updateUrl(params);
  };

  const getUrlParameter = (parameter: string): string | null => {
    const params = getSearchParams();
    return params.get(parameter);
  };

  const getAllUrlParameters = (): Record<string, string> => {
    const params = getSearchParams();
    return Object.fromEntries(params.entries());
  };

  const clearUrlParameter = (parameter: string) => {
    const params = getSearchParams();
    params.delete(parameter);
    updateUrl(params);
  };

  const clearAllUrlParameters = () => {
    navigate(location.pathname, { replace: true });
  };

  return {
    setUrlParameter,
    getUrlParameter,
    getAllUrlParameters,
    clearUrlParameter,
    clearAllUrlParameters,
  };
};

export default useUrlParameter;
