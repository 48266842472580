// useApi.js or useApi.ts
import { useState, useEffect } from "react";

import { useToast } from "@chakra-ui/react";
import { FileUploadMetadata } from "@shared/models";
import axios from "axios";

const useGetResume = () => {
  const [data, setData] = useState<FileUploadMetadata | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const toast = useToast();

  const url = "/api/file/resume";
  const method = "GET";

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios({
          url,
          method,
        });
        setData(response.data);
        setError("");
        setIsSuccess(true);
      } catch (error) {
        setError(error as string);
        setData(null);
        setIsSuccess(false);
        console.error(error);
        toast({
          title: "Error!",
          description: "There was an error getting your saved resume.",
          status: "error",
          position: "top",
          duration: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, method]);

  const refetch = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        url,
        method,
      });
      setData(response.data);
      setError("");
      setIsSuccess(true);
    } catch (error) {
      setError(error as string);
      setData(null);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, refetch, isSuccess };
};

export default useGetResume;
