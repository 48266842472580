import axios, { AxiosProgressEvent } from "axios";

import { FileUploadMetadata, UploadCategory } from "../../../../shared/models";

export interface UploadFileArgs {
  file: File;
  onProgress: (percentage: number) => void;
}

export const uploadFile = async ({ file, onProgress }: UploadFileArgs) => {
  if (!file) return;
  const { data } = await axios.post("/api/file/presigned-upload-url", {
    fileName: file.name,
    fileType: file.type,
  });

  await axios.put(data.presignedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (e: AxiosProgressEvent) => {
      const progress = Math.round((e.loaded * 100) / (e?.total || 1));
      onProgress(progress);
    },
  });

  return data as FileUploadMetadata;
};

type DownloadFileArgs = {
  category: UploadCategory;
  originalFilename: string;
};

export const downloadFile = async ({ category, originalFilename }: DownloadFileArgs) => {
  const response = await axios.post("/api/file/presigned-download-url", {
    category: category,
  });

  const { presignedUrl } = response.data;

  // Download the file using the presigned URL - this allows us to wait for the download to complete
  // const downloadResponse = await fetch(presignedUrl);
  const downloadResponse = await axios.get(presignedUrl, { responseType: "blob" });
  // if (!downloadResponse.ok) {
  //   console.error("Error downloading file:", downloadResponse.statusText);
  //   return;
  // }

  // Convert the response to a blob
  // const blob = await downloadResponse.blob();
  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(downloadResponse.data);

  // Create a temporary link element
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute to specify the desired file name
  link.setAttribute("download", originalFilename);

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document body
  document.body.removeChild(link);

  // Revoke the object URL to free up memory
  window.URL.revokeObjectURL(url);

  // Wait an arbitrary amount of time before returning to allow the browser to process the download
  await new Promise((resolve) => setTimeout(resolve, 150));
};
