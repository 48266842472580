import { useEffect } from "react";

import { Center, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { RoutePath } from "../../../../../shared/models";
import { useAthleteProfile } from "../../hooks/useAthleteProfile";
import { getProfileImage } from "../../services/file-api";

import { AthletePage } from "./index";

export function AthleteProfilePage() {
  const navigate = useNavigate();

  const {
    data: athleteProfile,
    isFetching: isFetchingAthleteProfile,
    isLoading: isLoadingAthleteProfile,
    isSuccess: isSuccessAthleteProfile,
  } = useAthleteProfile();

  const { data: profileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!isSuccessAthleteProfile) return;
    if (athleteProfile && athleteProfile?.sections.length === 0) {
      navigate(RoutePath.ATHLETE_ONBOARDING);
    }
  }, [athleteProfile, isFetchingAthleteProfile, isSuccessAthleteProfile]);

  if (isLoadingAthleteProfile || isFetchingAthleteProfile || !athleteProfile) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return <AthletePage athleteProfile={athleteProfile} profileImage={profileImage} />;
}
