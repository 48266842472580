import Hotjar from "@hotjar/browser";

const siteId = import.meta.env.VITE_HOTJAR_SITE_ID;
const hotjarVersion = import.meta.env.VITE_HOTJAR_VERSION;

export function initializeHotjar() {
  if (import.meta.env.MODE !== "development" && siteId && hotjarVersion) {
    Hotjar.init(Number(siteId), Number(hotjarVersion));
  }
}
