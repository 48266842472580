import * as React from "react";

import { ColorModeScript } from "@chakra-ui/react";
import { ClerkProvider } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import { initializeHotjar } from "./hotjar-setup";

// fonts
import "@fontsource-variable/inter";
import "@fontsource-variable/quicksand";

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: "https://be10f6bf6072a6634c3eca242a8b14a5@o4504119830315008.ingest.us.sentry.io/4506984167702528",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Initialize Hotjar
  initializeHotjar();
}

// Let's make sure the required env vars exist before going forward
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

import { theme } from "~/styles/theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode>
  <ClerkProvider
    publishableKey={PUBLISHABLE_KEY}
    appearance={{
      variables: { colorPrimary: "#604ABB" },
      elements: {
        formButtonPrimary: "bg-slate-500 hover:bg-slate-400 text-sm normal-case",
      },
    }}
  >
    <ColorModeScript initialColorMode={theme.config?.initialColorMode} />
    <App />
  </ClerkProvider>,
  // </React.StrictMode>
);
