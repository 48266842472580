import { useEffect, useState, useMemo } from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  Spinner,
  Text,
  UnorderedList,
  useToast,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { BsPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { AthleteContentSection } from "../../../../../../shared/athleteModels";
import { RoutePath } from "../../../../../../shared/models";
import { emptySectionStarter } from "../../../../../../shared/resumeContentConfig";
import { useAppStore } from "../../../app-store";
import { AthleteProfileContentCard } from "../../../components/cards/AthleteProfileContentCard";
import { Page } from "../../../components/Page";
import VideoTile from "../../../components/tiles/VideoTile";
import {
  useAthleteContentMutation,
  useAthleteProfile,
  useAthleteProfileMutation,
} from "../../../hooks/useAthleteProfile";
import { useProfileSections } from "../../../hooks/useProfileSections";
import { getProfileImage } from "../../../services/file-api";
import { BuildAthleteInfo } from "../../onboarding/athlete-onboarding/BuildAthleteInfo";
import AthleteProfileCard from "../AthleteProfileCard";

import MessageTile from "~/components/tiles/MessageTile";
import { listenToUserConversations } from "~/services/firebase-service";
import { MessagesApi } from "~/services/messages-api";

export interface DashboardProfileSection extends AthleteContentSection {
  isNew?: boolean;
  isEmpty?: boolean;
  isUpdating?: boolean;
  isComplete?: boolean;
}

const ToastSaveAllComponent = (handleSaveAll: () => void, handleDiscardAll: () => void) => {
  return (
    <Box
      bg="#EDB02B"
      backdropFilter="blur(10px)"
      border="2px solid white"
      opacity={0.9}
      p={4}
      py={2}
      mt={[8, 0]}
      borderRadius="lg"
      maxW="600px"
      w="full"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Heading size={["sm", "md"]}>AI Built Resume Profile Updates</Heading>
      <HStack>
        <Button variant="outline" colorScheme="black" size={["sm", "md"]} border="2px solid black" onClick={handleSaveAll}>
          Save All
        </Button>
        <Popover>
          <PopoverTrigger>
            <IconButton aria-label="Discard changes" icon={<CloseIcon />} variant="ghost" colorScheme="blackAlpha" />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">Discard Unsaved Changes?</PopoverHeader>
            <PopoverBody>All unsaved changes will be lost.</PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="ghost" colorScheme="red" onClick={handleDiscardAll}>
                  Discard Unsaved
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </HStack>
    </Box>
  );
};

//FIXME: ** LOOK AT ME **
//FIXME: In general things are functioning, but I need to handle the nuances of someone onboarding and handling all the different permutations of their actions.
//FIXME: ** LOOK AT ME **

export function AthleteDashboardPage() {
  const navigate = useNavigate();
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [isOnboarding, setIsOnboarding] = useAppStore((state) => [state.isOnboarding, state.setIsOnboarding]);
  const [suppressPageLoading, setSuppressPageLoading] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [showAthleteDetailsForm, setShowAthleteDetailsForm] = useState(false);
  const [sections, setSections] = useState<DashboardProfileSection[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean | null>(null);
  const [athleteResumeData, setAthleteResumeData] = useAppStore((state) => [
    state.athleteResumeData,
    state.setAthleteResumeData,
  ]);
  const {
    data: athleteProfile,
    isSuccess: isSuccessAthleteProfile,
    isFetching: isFetchingAthleteProfile,
  } = useAthleteProfile();
  const toast = useToast();

  const {
    data: profileImage,
    isLoading: isLoadingProfileImage,
    isFetching: isFetchingProfileImage,
  } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnMount: false,
  });

  const { determineColSpan, mergeSections, groupFlexSectionsIntoRows } = useProfileSections();
  const { mutate: updateAthleteContent, isPending: isUpdatingContentSection } = useAthleteContentMutation();
  const { mutate: updateAthleteProfile, isPending: isUpdatingProfile } = useAthleteProfileMutation();

  const { summarySection, workExperienceSection, topRowFlexSections, theRestFlexSections } = useMemo(() => {
    const summarySection = sections.find((section) => section.type === "summary");
    const workExperienceSection = sections.find((section) => section.type === "work_experience");
    const rowGroupedSections = groupFlexSectionsIntoRows(sections);
    const topRowFlexSections = rowGroupedSections[0] || [];
    const theRestFlexSections = rowGroupedSections.slice(1);

    return {
      summarySection,
      workExperienceSection,
      topRowFlexSections,
      theRestFlexSections,
    };
  }, [sections]);

  const minimumSectionsComplete =
    sections.find((section) => section.type === "summary")?.isComplete &&
    sections.find((section) => section.type === "work_experience")?.isComplete &&
    sections.find((section) => section.type === "athletics")?.isComplete &&
    sections.find((section) => section.type === "education")?.isComplete;

  const showOnboardingHelper = isOnboarding || !minimumSectionsComplete;

  useEffect(() => {
    if (!isSuccessAthleteProfile) return;
    if (athleteProfile && athleteProfile?.displayName === "") {
      navigate(RoutePath.ATHLETE_ONBOARDING);
    }
  }, [athleteProfile, isFetchingAthleteProfile, isSuccessAthleteProfile]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (!athleteProfile?.sections || !sections.length) return;

    const hasChanges = sections.some((section) => {
      // Skip sections with empty content
      if (!section.content?.trim()) return false;

      const originalSection = athleteProfile.sections.find((s) => s.type === section.type);
      return originalSection?.content?.trim() !== section.content?.trim();
    });

    setHasUnsavedChanges(hasChanges);
  }, [sections, athleteProfile]);

  useEffect(() => {
    const toastId = "save-all-toast";

    if (isOnboarding && athleteResumeData && hasUnsavedChanges) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          position: "top",
          duration: null,
          render: () => ToastSaveAllComponent(handleAllContentSave, handleDiscardAll),
        });
      }
    }

    if (isOnboarding && hasUnsavedChanges === false) {
      // once all resume data is saved (or canceled), we can complete the onboarding process
      handleOnboardingComplete();
    }
  }, [isOnboarding, athleteResumeData, hasUnsavedChanges]);

  function handleDiscardAll() {
    if (!athleteProfile?.sections) return;

    const resetSections = athleteProfile.sections.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: !section.content || section.content?.trim() === "",
      isComplete: section.content?.trim() !== "",
    }));

    const emptySections = emptySectionStarter.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: true,
      isComplete: false,
    }));

    const mergedSections = mergeSections(emptySections, resetSections, []);
    setSections(mergedSections);
    setAthleteResumeData(null);
    handleOnboardingComplete();
    setHasUnsavedChanges(false);
  }

  // get messages
  const { data: conversations, refetch: refetchConversations } = useQuery({
    queryKey: ["conversations"],
    queryFn: () => MessagesApi.getUserConversations(athleteProfile!.userId),
  });

  useEffect(() => {
    if (!athleteProfile) return;
    listenToUserConversations(athleteProfile!.userId, undefined, refetchConversations, () => {
      refetchConversations();
    });
  }, [conversations, athleteProfile?.userId]);

  function handleAllContentSave() {
    const profile = {
      sections: sections.map((section) => ({
        ...(section.id ? { id: section.id } : {}),
        updates: section,
      })),
    };
    updateAthleteProfile({ profile });

    handleOnboardingComplete();
  }

  function handleOnboardingComplete() {
    toast.close("save-all-toast");
    setIsOnboarding(false);
  }

  useEffect(() => {
    if (!athleteProfile || isFetchingAthleteProfile) return;
    let preppedResumeSections: DashboardProfileSection[] = [];
    if (athleteResumeData && athleteResumeData.sections && athleteResumeData.sections.length > 0) {
      // Filter out resume sections that already exist in the profile
      const uniqueResumeSections = athleteResumeData.sections.filter(
        (resumeSection) =>
          !athleteProfile.sections?.some((profileSection) => profileSection.type === resumeSection.type),
      );

      preppedResumeSections = uniqueResumeSections.map((section) => ({
        ...section,
        isNew: true,
        isUpdating: false,
        isEmpty: !section.content || section.content?.trim() === "",
        isComplete: false,
      }));
    }

    let preppedProfileSections: DashboardProfileSection[] = [];
    if (athleteProfile.sections && athleteProfile.sections.length > 0) {
      preppedProfileSections = athleteProfile.sections.map((section) => ({
        ...section,
        isNew: false,
        isUpdating: false,
        isEmpty: false,
        isComplete: section.content?.trim() !== "",
      }));
    }

    const emptySections = emptySectionStarter.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: true,
      isComplete: false,
    }));

    const mergedSections = mergeSections(emptySections, preppedProfileSections, preppedResumeSections);
    setSections(mergedSections);
  }, [athleteResumeData?.sections, athleteProfile?.sections, isFetchingAthleteProfile]);

  const handleSectionSave = (section: DashboardProfileSection) => {
    if (!userInfo) return;
    const updatedSections = sections.map((s) => {
      if (s.type === section.type) {
        return { ...section, isUpdating: true, isComplete: section.content?.trim() !== "" };
      }
      return s;
    });
    setSections(updatedSections);
    const contentToSave: Partial<AthleteContentSection> = {
      type: section.type,
      index: section.index ?? 0,
      content: section.content,
    };
    updateAthleteContent({
      userId: userInfo.id,
      ...(section.id ? { contentId: section.id } : {}),
      content: contentToSave,
    });
  };

  function renderSections() {
    // if (sections.length === 0) return <></>;
    return (
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} w="100%">
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <AthleteProfileCard athleteProfile={athleteProfile} profileImage={profileImage} editMode />
        </GridItem>

        {showOnboardingHelper && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Box w="100%" bg="brand.100" p={4} rounded="xl" border="2px dashed" borderColor="black">
              <Stack direction={{ base: "column", md: "row" }} justify="space-between" mb={4}>
                <Heading mb={2} size="md" color="black">
                  Welcome to your dashboard!
                </Heading>
                {athleteProfile?.sections?.length === 0 && !athleteResumeData && (
                  <Button
                    colorScheme="brand"
                    bg="#EDB02B"
                    w={["min-content", "auto"]}
                    color="black"
                    _hover={{ color: "white", bg: "brand.500" }}
                    onClick={() => navigate(RoutePath.ATHLETE_ONBOARDING)}
                    fontSize="xl"
                    alignSelf={{ base: "center", md: "flex-end" }}
                    shadow="md"
                  >
                    Fill Profile with Resume
                  </Button>
                )}
              </Stack>
              <Text color="blackAlpha.800">
                Below are different profile sections you can update to help you get started. If the section has a gray
                background, it means that it is currently empty and we won't show it to potential recruiters. We won't
                make your profile visible to recruiters until you've had a chance to fill out the primary sections
                (Summary, Education, Work Experience, and Athletic Experience). To see what your profile looks like to
                recruiters, click on the profile icon{" ->"}
                <Icon fontSize="1.2em" as={BsPersonFill} mx={1} color="brand.500" position="relative" top="3px" />
                in the top left of the page.
              </Text>
              <Text color="blackAlpha.800" fontStyle="italic" textAlign="right" mt={2} fontSize="sm">
                This help section will disappear once you've completed the primary sections.
              </Text>
            </Box>
          </GridItem>
        )}
        {!isOnboarding && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Box bg="white" w="100%" p={6} rounded="xl" border="2px solid" borderColor="brand.500">
              <HStack justify="space-between">
                <Heading size="md" color="brand.500">
                  Boost Your Visibility
                </Heading>
                <ButtonGroup>
                  <Button
                    variant="outline"
                    colorScheme="brand"
                    border="2px solid"
                    onClick={() => setShowTips(!showTips)}
                  >
                    Tips
                  </Button>
                  <Button variant="solid" colorScheme="brand" onClick={() => setShowAthleteDetailsForm(true)}>
                    Update Details
                  </Button>
                </ButtonGroup>
              </HStack>
              {showTips && (
                <UnorderedList mt={4} spacing={1}>
                  <ListItem>
                    <Text>
                      <b>Keep your profile details up to date.</b> Current location and location preferences won't land
                      you a job, but they will help recruiters find matches they want to reach out to.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <b>Complete your profile!</b> You currently have{" "}
                      {sections.reduce((acc, curr) => acc + (curr.content?.trim() === "" ? 1 : 0), 0)} incomplete
                      sections. Sections with grey backgrounds have no content and are hidden from your profile.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      <b>Respond to messages.</b> Recruiters will message you if they want to learn more about you.
                    </Text>
                  </ListItem>
                </UnorderedList>
              )}
            </Box>
          </GridItem>
        )}

        {summarySection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={summarySection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {topRowFlexSections.length > 0 &&
          topRowFlexSections.map((section, index) => (
            <GridItem
              key={section.type + index}
              colSpan={{
                base: 1,
                md: determineColSpan(section),
              }}
            >
              <AthleteProfileContentCard section={section} onEdit={handleSectionSave} />
            </GridItem>
          ))}

        {workExperienceSection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={workExperienceSection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {theRestFlexSections.length > 0 &&
          theRestFlexSections.map((row, index) => {
            const isLastRow = index === theRestFlexSections.length - 1;
            const hasOneItem = row.length === 1;
            const forceFullWidth = isLastRow || hasOneItem;
            return row.map((section, index) => (
              <GridItem
                key={section.type + index}
                colSpan={{
                  base: 1,
                  md: forceFullWidth ? 3 : determineColSpan(section),
                }}
              >
                <AthleteProfileContentCard section={section} onEdit={handleSectionSave} />
              </GridItem>
            ));
          })}
      </Grid>
    );
  }

  useEffect(() => {
    if (isUpdatingContentSection) {
      setSuppressPageLoading(true);
    }

    setTimeout(() => {
      setSuppressPageLoading(false);
    }, 1100);
  }, [isUpdatingContentSection]);

  if (
    (!isSuccessAthleteProfile ||
      !sections ||
      isFetchingAthleteProfile ||
      isUpdatingProfile ||
      isLoadingProfileImage ||
      isFetchingProfileImage) &&
    !suppressPageLoading
  ) {
    let message = "Loading your dashboard...";
    if (isUpdatingProfile) {
      message = "Updating your profile...";
    }
    return (
      <Page>
        <Center h="90vh" flexDirection="column">
          <Heading size="lg">{message}</Heading>
          <Spinner color="brand.500" size="xl" speed=".60s" thickness="4px" />
        </Center>
      </Page>
    );
  }

  if (showAthleteDetailsForm) {
    return <BuildAthleteInfo onClose={() => setShowAthleteDetailsForm(false)} />;
  }

  return (
    <Page>
      <Stack
        direction={{ base: "column-reverse", md: "row" }}
        w="full"
        gap={3}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        {renderSections()}
        {!isOnboarding && (
          <VStack w={{ base: "full", md: "380px" }} h={{ base: "auto", md: "100vh" }} gap={3}>
            <VideoTile
              thumbnailUrl={"steph.png"}
              videoUrl={"https://www.youtube.com/watch?v=KxB62peJNw4?cc_load_policy=1"}
              alt={"Welcome to HCA"}
            />
            {conversations && <MessageTile conversations={conversations} />}
          </VStack>
        )}
      </Stack>
    </Page>
  );
}
