import { memo } from "react";

import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";

interface DownloadFileButtonProps {
  disabled?: boolean;
  onDownloadClicked?: () => void;
  iconButtonProps?: IconButtonProps;
}

export const DownloadFileButton = memo(({ onDownloadClicked, iconButtonProps, disabled }: DownloadFileButtonProps) => {
  return (
    <IconButton
      variant={"secondary"}
      isDisabled={disabled}
      aria-label="Download"
      icon={<FaDownload />}
      {...iconButtonProps}
      onClick={onDownloadClicked}
    />
  );
});
