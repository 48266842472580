import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Box, Center, Heading, Spinner, VStack, SimpleGrid, Link } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { UniversityAthleteCount } from "../../../../../shared/models";
import { getHCAInsights } from "../../services/admin-api";

export interface HCAInsights {
  universityAthleteCounts: UniversityAthleteCount[];
}

interface CellRendererParams extends ICellRendererParams {
  data: UniversityAthleteCount;
}

export const InsightsTab = () => {
  const { data: insights, isLoading } = useQuery<HCAInsights>({
    queryKey: ["insights"],
    queryFn: () => getHCAInsights(),
  });

  if (isLoading) {
    return (
      <Center w={"full"} h={"full"}>
        <Spinner />
      </Center>
    );
  }

  type GridData = UniversityAthleteCount[] | undefined;

  const renderGrid = (title: string, data: GridData, columns: ColDef[]) => {
    if (!data) {
      return null;
    }
    return (
      <Box w="full">
        <Heading size={["md", "lg"]} mb={2}>
          {title}
        </Heading>
        <Box className="ag-theme-quartz" style={{ width: "100%", height: 500 }}>
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            defaultColDef={{ sortable: true, filter: true, resizable: true }}
            pagination={true}
            paginationAutoPageSize={true}
          />
        </Box>
      </Box>
    );
  };

  return (
    <VStack w={"100%"} overflowY={"auto"} h={["100%", "80vh"]} spacing={4}>
      <SimpleGrid columns={[1, 2]} spacing={4} w="full">
        {renderGrid("University Athlete Counts", insights?.universityAthleteCounts, [
          {
            headerName: "University",
            field: "universityName",
            flex: 2,
            cellRenderer: (params: CellRendererParams) => {
              // build a link to the tab=athletes&university=universityName
              const url = new URL(`/admin`, window.location.origin);
              url.searchParams.set("tab", "athletes");
              url.searchParams.set("university", params.data.universityName);
              return <Link href={url.toString()}>{params.data.universityName}</Link>;
            },
          },
          { headerName: "Total Athletes", field: "totalAthleteCount", flex: 1 },
          { headerName: "Active", field: "activeAthleteCount", flex: 1 },
          { headerName: "Pending", field: "pendingAthleteCount", flex: 1 },
        ])}
        {/* Add more grids here as needed */}
      </SimpleGrid>
    </VStack>
  );
};
