import { useState, useMemo } from "react";

import { ChatIcon } from "@chakra-ui/icons";
import {
  Card,
  Box,
  CardHeader,
  Heading,
  CardBody,
  VStack,
  HStack,
  Text,
  Stack,
  Flex,
  Button,
  Hide,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { AthleteProfile } from "../../../../../shared/athleteModels";
import { UserRole } from "../../../../../shared/models";
import { useAppStore } from "../../app-store";
import { EditableAvatar } from "../../components/EditableAvatar";
import MessagingModal from "../../components/MessagingModal";
import { MessagesApi } from "../../services/messages-api";

interface AthleteProfileCardProps {
  athleteProfile?: AthleteProfile;
  profileImage?: { url: string } | null;
  editMode?: boolean;
}

const AthleteProfileCard: React.FC<AthleteProfileCardProps> = ({ athleteProfile, profileImage, editMode = false }) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);

  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false);

  const { data: conversation } = useQuery({
    queryKey: ["conversation", userInfo?.id, athleteProfile?.userId],
    queryFn: () => MessagesApi.getConversationByParticipants(userInfo?.id ?? 0, athleteProfile?.userId ?? 0),
    enabled: !!userInfo?.id && !!athleteProfile?.userId && userInfo.id !== athleteProfile.userId,
  });

  const isRecruiter = userInfo?.role === UserRole.RECRUITER || userInfo?.role === UserRole.COMPANY_ADMIN;
  const canMessage = (isRecruiter || userInfo?.role === UserRole.ADMIN) && userInfo?.id !== athleteProfile?.userId;

  if (!athleteProfile) {
    return null;
  }

  let profileName = athleteProfile?.displayName ? athleteProfile?.displayName : "Athlete";

  if (isRecruiter) {
    profileName = athleteProfile?.displayName ? athleteProfile?.displayName.split(" ")[0] : "Athlete";
  }

  const summaryPreview = useMemo(() => {
    const summarySection = athleteProfile?.sections?.find((section) => section.type === "summary");
    return summarySection?.content?.trim() || "No summary available";
  }, [athleteProfile?.sections]);

  return (
    <>
      <Card size="lg" w="full" overflow={"hidden"}>
        <Box bg="blackAlpha.300" w="full" h={["60px", 140, 140]} alignContent={"flex-end"} px={6}>
          <HStack justifyContent={"space-between"}>
            <EditableAvatar imageUrl={profileImage?.url} editMode={editMode} />
          </HStack>
        </Box>
        <CardHeader paddingBottom={1} paddingTop={16}>
          <VStack alignItems={"flex-start"}>
            <HStack justifyContent={"space-between"} w="full">
              <Stack direction={["column", "row"]} alignItems="flex-start">
                <Heading size="lg">{profileName}</Heading>
                <HStack>
                  <Hide below="md">
                    <Text fontSize="24px" fontWeight="normal" mx={3}>
                      |
                    </Text>
                  </Hide>
                  <Text fontSize="24px" fontWeight="bold" color={"brand.primary"} mr={3}>
                    {athleteProfile.readyToWork ? "Open to Work" : "Recruit Me"}
                  </Text>
                  <Text fontSize="22px" color={"gray.500"} fontStyle="italic" fontWeight="normal">
                    {athleteProfile.workPreference}
                  </Text>
                </HStack>
              </Stack>

              <Flex h="100%" justifyContent={"flex-end"} alignItems={"flex-start"}>
                {canMessage && (
                  <Button
                    leftIcon={<ChatIcon fontSize={24} color="brand.primary" />}
                    bg="gray.100"
                    size="lg"
                    p={4}
                    _hover={{ border: "1px solid", borderColor: "brand.primary" }}
                    onClick={() => setIsMessagingModalOpen(true)}
                  >
                    <Text fontSize={18} color="slate.500" mr={2}>
                      Message
                    </Text>
                  </Button>
                )}
              </Flex>
            </HStack>

            <Text
              size="sm"
              color="slate.500"
              fontWeight="normal"
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
              w="full"
              minW={0}
              pr={{ base: 2, md: 5 }}
            >
              {summaryPreview}
            </Text>
          </VStack>
        </CardHeader>
        <CardBody paddingTop={1}>
          <VStack gap={0} w="full" alignItems="flex-start">
            <Stack direction={["column", "row"]} w="full">
              {/* <Text fontSize="20px" fontWeight="semibold" color={"blackAlpha.700"}>
                What about a mini description or tagline or what kind of work they are looking for?
              </Text> */}
            </Stack>
          </VStack>
        </CardBody>
      </Card>
      <MessagingModal
        isOpen={isMessagingModalOpen}
        onClose={() => setIsMessagingModalOpen(false)}
        recipient={{
          recipientId: athleteProfile.userId,
          recipientName: profileName,
        }}
        conversation={conversation ?? null}
      />
    </>
  );
};

export default AthleteProfileCard;
