import { Box, FormControl, FormLabel, Stack, Flex, Hide } from "@chakra-ui/react";
import { Gender, SportItem } from "@shared/models";
import { Select as ReactSelect } from "chakra-react-select";

import { SearchBar } from "./SearchBar";

type AthleteFilterProps = {
  sportList?: SportItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilterChange: (name: string) => (selectedOption: any) => void;
  handleSearchChange: (value: string) => void;
  handleSubmit: () => void;
  showGenderFilter?: boolean;
  showUnclaimedAthletesFilter?: boolean;
};

const AthleteFilter: React.FC<AthleteFilterProps> = ({
  sportList,
  onFilterChange,
  handleSearchChange,
  handleSubmit,
  showGenderFilter = false,
  showUnclaimedAthletesFilter = false,
}) => {
  return (
    <FormControl>
      <Stack gap={2} direction={["column", "row"]} align="flex-end">
        <Hide below="md">
          <Flex gap={2} align="flex-end" width="100%">
            <Box width="100%">
              <FormLabel>Graduation Year</FormLabel>
              <ReactSelect
                size="sm"
                colorScheme="brand"
                selectedOptionColorScheme="brand"
                options={[
                  { value: undefined, label: "Any", isFixed: true },
                  { value: "2024", label: "2024" },
                  { value: "2025", label: "2025" },
                  { value: "2026", label: "2026" },
                  { value: "2027", label: "2027" },
                  { value: "2028", label: "2028" },
                ]}
                name="graduationYear"
                onChange={onFilterChange("graduationYear")}
                placeholder="Any"
              />
            </Box>
            <Box width="100%">
              <FormLabel>Sport</FormLabel>
              <ReactSelect
                size="sm"
                colorScheme="brand"
                selectedOptionColorScheme="brand"
                name="primarySport"
                options={[
                  { value: undefined, label: "Any" },
                  ...(sportList?.map((sport) => ({ value: sport.sport, label: sport.sport })) || []),
                ]}
                onChange={onFilterChange("primarySport")}
                placeholder="Any"
              />
            </Box>
            <Box width="100%">
              <FormLabel size="sm">Ready to Work</FormLabel>
              <ReactSelect
                colorScheme="brand"
                selectedOptionColorScheme="brand"
                size="sm"
                name="readyToWork"
                options={[
                  { value: undefined, label: "Any" },
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                onChange={onFilterChange("readyToWork")}
                placeholder="Any"
              />
            </Box>
            {showGenderFilter && (
              <Box width="100%">
                <FormLabel size="sm">Gender</FormLabel>
                <ReactSelect
                  colorScheme="brand"
                  selectedOptionColorScheme="brand"
                  size="sm"
                  name="gender"
                  options={[
                    { value: undefined, label: "Any" },
                    { value: Gender.MALE, label: "Male" },
                    { value: Gender.FEMALE, label: "Female" },
                  ]}
                  onChange={onFilterChange("gender")}
                  placeholder="Any"
                />
              </Box>
            )}
            {showUnclaimedAthletesFilter && (
              <Box width="100%">
                <FormLabel size="sm">Unclaimed Athletes</FormLabel>
                <ReactSelect
                  colorScheme="brand"
                  selectedOptionColorScheme="brand"
                  size="sm"
                  name="hidePendingAthletes"
                  options={[
                    { value: false, label: "Show All" },
                    { value: true, label: "Hide Unclaimed" },
                  ]}
                  onChange={onFilterChange("hidePendingAthletes")}
                  placeholder="Any"
                />
              </Box>
            )}
          </Flex>
        </Hide>
        <Box width={["100%", "50%"]} height="100%" marginLeft={[0, 8]}>
          <SearchBar hideButton onClick={handleSubmit} onChange={handleSearchChange} placeholder="Search Athlete..." />
        </Box>
      </Stack>
    </FormControl>
  );
};

export default AthleteFilter;
