import { useEffect, useState } from "react";

import { Center, Heading, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { BuildAthleteInfo } from "./BuildAthleteInfo";
import { GetAthleteResume } from "./GetAthleteResume";
import { RoutePath } from "../../../../../../shared/models";
import { useAppStore } from "../../../app-store";
import { useAthleteProfile } from "../../../hooks/useAthleteProfile";

enum OnboardingSteps {
  DETERMINE_STARTING_STEP = "determineStartingStep",
  RESUME_OR_SCRATCH = "resumeOrScratch",
  BUILD_BASIC_INFO = "buildBasicInfo",
  ROUTE_TO_DASHBOARD = "routeToDashboard",
}

export function AthleteOnboardingPage() {
  const navigate = useNavigate();
  const [setIsOnboarding] = useAppStore((state) => [state.setIsOnboarding]);
  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(OnboardingSteps.DETERMINE_STARTING_STEP);
  const { data: athleteProfile, isSuccess: isSuccessAthleteProfile } = useAthleteProfile();

  useEffect(() => {
    const navigationType = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
    if (navigationType.type === "reload") {
      setIsOnboarding(true);
    }

    if (!isSuccessAthleteProfile) return;

    let startingStep: OnboardingSteps = OnboardingSteps.RESUME_OR_SCRATCH;

    if (!athleteProfile) {
      startingStep = OnboardingSteps.RESUME_OR_SCRATCH;
    } else {
      const athleteInfoIncomplete = athleteProfile && athleteProfile.firstName === "";
      const athleteContentSectionsStarted = athleteProfile && athleteProfile.sections.length > 0;

      if (athleteContentSectionsStarted) startingStep = OnboardingSteps.ROUTE_TO_DASHBOARD;
      if (athleteInfoIncomplete) startingStep = OnboardingSteps.RESUME_OR_SCRATCH;
    }

    setTimeout(() => {
      setOnboardingStep(startingStep);
    }, 1500);
  }, [athleteProfile, isSuccessAthleteProfile]);

  useEffect(() => {
    if (onboardingStep === OnboardingSteps.ROUTE_TO_DASHBOARD) {
      navigate(RoutePath.ATHLETE_DASHBOARD);
    }
  }, [onboardingStep]);

  const goToNextStep = (nextStep: OnboardingSteps) => {
    setOnboardingStep(nextStep);
  };

  const startFromScratch = () => {
    goToNextStep(OnboardingSteps.BUILD_BASIC_INFO);
    setIsOnboarding(false);
  };

  const renderStep = () => {
    switch (onboardingStep) {
      case OnboardingSteps.RESUME_OR_SCRATCH:
        return (
          <GetAthleteResume
            continueToNextStep={() => goToNextStep(OnboardingSteps.BUILD_BASIC_INFO)}
            startFromScratch={startFromScratch}
          />
        );
      case OnboardingSteps.BUILD_BASIC_INFO:
        return <BuildAthleteInfo onClose={() => goToNextStep(OnboardingSteps.ROUTE_TO_DASHBOARD)} />;
      case OnboardingSteps.ROUTE_TO_DASHBOARD:
        return <></>;
      default:
        return (
          <Center h="90vh" bgColor="white" w="100%">
            <Heading>How did you get here?</Heading>
          </Center>
        );
    }
  };

  if (onboardingStep === OnboardingSteps.DETERMINE_STARTING_STEP) {
    return (
      <Center h="90vh" w="100%" flexDirection="column" gap={8}>
        <Heading textAlign="center">Setting up your onboarding experience...</Heading>
        <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
      </Center>
    );
  }
  return renderStep();
}
