import { useCallback, forwardRef, useImperativeHandle, useState } from "react";

import { Search2Icon } from "@chakra-ui/icons";
import { Box, Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

type SearchBarProps = {
  onClick?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  hideButton?: boolean;
};

export const SearchBar = forwardRef<unknown, SearchBarProps>(
  ({ hideButton, onClick, onChange, size = "sm", placeholder = "Search..." }, ref) => {
    const [inputValue, setInputValue] = useState("");

    useImperativeHandle(ref, () => ({
      clearInput: () => setInputValue(""),
    }));

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      if (onChange) onChange(event.target.value);
    }, []);

    const handleSearchClick = useCallback(() => {
      if (onClick) onClick(inputValue);
    }, [inputValue, onClick]);

    const handleKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          handleSearchClick();
        }
      },
      [handleSearchClick],
    );

    return (
      <>
        <Box display="flex" width="100%">
          <InputGroup size={size}>
            <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.600" />} />
            <Input
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onChange={handleInputChange}
              onKeyUp={handleKeyPress}
              aria-label="Search Input"
              borderRadius={4}
            />
          </InputGroup>

          {!hideButton && (
            <Button
              size={size}
              borderRadius={4}
              colorScheme="purple"
              onClick={handleSearchClick}
              aria-label="Search Button"
              marginLeft={2}
            >
              Search
            </Button>
          )}
        </Box>
      </>
    );
  },
);
