import { useState } from "react";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Avatar, IconButton, useBreakpointValue } from "@chakra-ui/react";

import { FileHandlingButton } from "./files/FileHandlingButton";

interface EditableAvatarProps {
  imageUrl?: string;
  size?: string[];
  top?: number[];
  editMode?: boolean;
}

export const EditableAvatar = ({
  imageUrl,
  size = ["xl", "2xl"],
  top = [5, 12],
  editMode = false,
}: EditableAvatarProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const iconSize = isMobile ? "sm" : "md";

  return (
    <Box position="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Avatar size={size} src={imageUrl} bg="brand.primary" top={top} boxShadow="lg" />
      {editMode && (
        <Box position="absolute" top={top} right={-6} transform="translate(-50%, 0)">
          <FileHandlingButton
            label="Headshot"
            category="profile-picture"
            includeImageCropper={true}
            action="upload"
            customButton={
              <IconButton
                icon={<EditIcon fontSize="20px" />}
                aria-label="Edit profile picture"
                variant="solid"
                size={iconSize}
                bg={isHovered ? "brand.primary" : "white"}
                _hover={{ bg: "brand.400", color: "white" }}
                borderRadius="full"
                boxShadow="md"
              />
            }
          />
        </Box>
      )}
    </Box>
  );
};
