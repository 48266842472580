import React, { useState, useEffect } from "react";

import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Card,
  CardHeader,
  CardBody,
  Heading,
  HStack,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BsFillPlayBtnFill } from "react-icons/bs";

interface VideoTileProps {
  thumbnailUrl: string;
  videoUrl: string;
  alt: string;
}

const VideoTile: React.FC<VideoTileProps> = ({ thumbnailUrl, videoUrl, alt }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isExpanded, setIsExpanded] = useState(false);
  const [videoId, setVideoId] = useState<string | null>(null);
  const isLargerThanMd = useBreakpointValue({ base: false, md: true });
  const [showThumbnail, setShowThumbnail] = useState(isLargerThanMd);

  useEffect(() => {
    setShowThumbnail(isLargerThanMd);
  }, [isLargerThanMd]);

  const handleClick = () => {
    if (!showThumbnail) {
      setShowThumbnail(true);
    } else {
      const match = videoUrl.match(
        /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/,
      );
      if (match) {
        setVideoId(match[1]);
        onOpen();
      }
    }
  };

  return (
    <>
      <Card
        onClick={handleClick}
        w="full"
        cursor="pointer"
        overflow="hidden"
        boxShadow="md"
        transition="all 0.3s"
        rounded="xl"
        onMouseOver={() => setIsExpanded(true)}
        onMouseOut={() => setIsExpanded(false)}
      >
        <CardHeader p={4} bg="brand.primary" textColor="white" w="full">
          <HStack w="full">
            <Heading size="md" w="full">
              Welcome to HCA!
            </Heading>
            <Icon as={BsFillPlayBtnFill} fontSize="1.75rem" color="white" />
          </HStack>
        </CardHeader>
        <CardBody p={0}>
          {(isExpanded || isLargerThanMd) && showThumbnail && (
            <Box position="relative">
              <Image src={thumbnailUrl} alt={alt} />
              <Box position="absolute" top="5%" left="7%">
                <Icon as={BsFillPlayBtnFill} fontSize="2.5rem" color="white" />
              </Box>
            </Box>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Box
            as="iframe"
            title="YouTube video player"
            src={`https://www.youtube.com/embed/${videoId}`}
            width="100%"
            height="400px"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default VideoTile;
