import axios from "axios";

import { AppConfig } from "./app-config-service";
import { AthleteContentSection, AthleteInfoUpdate } from "../../../../shared/athleteModels";
import { AthleteInfo } from "../../../../shared/athleteModels";
import { PendingAthleteProfile, UserProfileInfo } from "../../../../shared/models";

export const getProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile`);
  return data as UserProfileInfo;
};

export const getSpecificProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}`);
  return data as UserProfileInfo;
};

export const postUserProfile = async (profileInfo: UserProfileInfo) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile`, profileInfo, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPendingProfilesByDomain = async (emailDomain?: string) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/pending`, {
    params: { emailDomain },
  });
  return data as PendingAthleteProfile[];
};

export const copyPendingProfileToUserProfile = async (profileId: number) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile/pending`, { profileId });
};

export const postAthleteInfo = async (data: { userId?: number; info: AthleteInfo }) => {
  const url = data.userId
    ? `${AppConfig.apiUrl}/api/athlete-info/${data.userId}`
    : `${AppConfig.apiUrl}/api/athlete-info`;
  const { data: responseData } = await axios.post(url, data.info);
  return responseData;
};

export const postAthleteContent = async (data: {
  userId: number;
  contentId?: number;
  content: Partial<AthleteContentSection>;
}) => {
  const url = `${AppConfig.apiUrl}/api/athlete-content/${data.contentId ? data.contentId : "new"}`;
  const { data: responseData } = await axios.post(url, data.content);
  return responseData;
};

export const postAthleteProfile = async (data: {
  userId?: number;
  profile: {
    info?: AthleteInfoUpdate;
    sections?: { id?: number; updates: Partial<AthleteContentSection> }[];
  };
}) => {
  const url = data.userId
    ? `${AppConfig.apiUrl}/api/athlete-profile/${data.userId}`
    : `${AppConfig.apiUrl}/api/athlete-profile`;
  const { data: responseData } = await axios.post(url, data.profile);
  return responseData;
};

export const getAthleteInfo = async (): Promise<AthleteInfo> => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info`);
  return data;
};

export const getSpecificAthleteInfo = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info/${userId}`);
  return data;
};

export const getAthleteProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-profile`);
  return data;
};

export const getSpecificAthleteProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-profile/${userId}`);
  return data;
};

export const getAllAthleteInfo = async (): Promise<AthleteInfo[]> => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info/all`);
  return data;
};
