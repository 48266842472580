import { AthleteContentSection } from "../../../../shared/athleteModels";
import { resumeContentConfig } from "../../../../shared/resumeContentConfig";
import { DashboardProfileSection } from "../pages/athlete/dashboard/AthleteDashboardPage";

interface SectionWithSize extends DashboardProfileSection {
  size: 1 | 2 | 3;
  priority: number;
  isUsed: boolean;
}

type AcceptableSectionFormats = DashboardProfileSection | AthleteContentSection;

export const useProfileSections = () => {
  const determineColSpan = (section: AcceptableSectionFormats) => {
    if (!section.content) return resumeContentConfig[section.type].emptySize ?? 1;
    const words = section.content.split(" ").length - 1;
    const dashBulletes = section.content.split("- ").length - 1;
    const asterisksBulletes = section.content.split("*  ").length - 1;
    const bullets = dashBulletes + asterisksBulletes;
    return bullets > 3 || words > 100 ? 2 : 1;
  };

  const groupFlexSectionsIntoRows = (sections: AcceptableSectionFormats[]): AcceptableSectionFormats[][] => {
    if (sections.length === 0) return [];

    const flexSectionKeys = Object.entries(resumeContentConfig)
      .filter(([, config]) => config.size === "flex")
      .map(([key]) => key);
    const flexSections = flexSectionKeys.flatMap((key) => sections.filter((section) => section.type === key));

    const sectionsWithSize: SectionWithSize[] = flexSections.map((section) => ({
      ...section,
      size: determineColSpan(section),
      priority: resumeContentConfig[section.type].priority ?? 0,
      isUsed: false,
    }));

    // Split sections by size and sort by priority (empty sections last)
    const size2Sections = sectionsWithSize
      .filter((section) => section.size === 2)
      .sort((a, b) => (a.isEmpty ? 1 : b.isEmpty ? -1 : a.priority - b.priority));

    const size1Sections = sectionsWithSize
      .filter((section) => section.size === 1)
      .sort((a, b) => (a.isEmpty ? 1 : b.isEmpty ? -1 : a.priority - b.priority));

    const rows: AcceptableSectionFormats[][] = [];

    // Match size 2 with size 1 sections when possible
    while (size2Sections.length > 0 && size1Sections.length > 0) {
      const size2Section = size2Sections.shift()!;
      const size1Section = size1Sections.shift()!;
      rows.push([size2Section, size1Section]);
    }

    // Handle remaining size 2 sections
    while (size2Sections.length > 0) {
      if (size2Sections.length >= 2) {
        // Take two size 2 sections and make one of them size 1
        const section1 = size2Sections.shift()!;
        const section2 = size2Sections.shift()!;
        section2.size = 1;
        rows.push([section1, section2]);
      } else {
        // Handle remaining single size 2 section
        rows.push([size2Sections.shift()!]);
      }
    }

    // Handle remaining size 1 sections
    let currentRow: SectionWithSize[] = [];
    size1Sections.forEach((section) => {
      if (currentRow.length === 3) {
        rows.push([...currentRow]);
        currentRow = [];
      }
      currentRow.push(section);
    });

    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    const swappedRows = rows.map((row, index) => (index % 2 === 0 ? row : row.reverse()));

    return swappedRows;
  };

  /**
   * Merges the base sections with the saved sections and the new sections.
   * This accepts multiple section types, but returns only DashboardProfileSection.
   * @param baseSections - The base sections to merge.
   * @param savedSections - The saved sections to merge.
   * @param newSections - The new sections to merge.
   * @returns The merged sections.
   */
  const mergeSections = (
    baseSections: AcceptableSectionFormats[],
    savedSections: AcceptableSectionFormats[],
    newSections: AcceptableSectionFormats[],
  ): DashboardProfileSection[] => {
    const mergedSections = [...baseSections];

    const updateSections = (sourceSections: AcceptableSectionFormats[], isNew: boolean) => {
      sourceSections.forEach((section) => {
        const existingIndex = mergedSections.findIndex((s) => s.type === section.type);
        if (existingIndex >= 0) {
          mergedSections[existingIndex] = section;
        } else {
          mergedSections.push({ ...section, isNew });
        }
      });
    };

    // First, layer in saved sections
    updateSections(savedSections, false);

    // Then, layer in new sections
    updateSections(newSections, true);

    return mergedSections;
  };

  return {
    groupFlexSectionsIntoRows,
    determineColSpan,
    mergeSections,
  };
};
