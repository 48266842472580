import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { RoutePath, UserRole } from "../../../../shared/models";
import { useAppStore } from "../app-store";
import { AwesomeHCASpinner } from "../components/AwesomeHCASpinner";
import { useAthleteProfile } from "../hooks/useAthleteProfile";

const UserRoleBasedRedirect: React.FC = () => {
  const navigate = useNavigate();
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const { data: athleteProfile, isSuccess: isSuccessAthleteProfile } = useAthleteProfile();
  const [setIsOnboarding] = useAppStore((state) => [state.setIsOnboarding]);

  useEffect(() => {
    if (userInfo) {
      switch (userInfo.role) {
        case UserRole.ATHLETE: {
          if (!isSuccessAthleteProfile) return;
          const athletePath = determineAthletePath();
          navigate(athletePath);
          break;
        }
        case UserRole.RECRUITER:
          navigate(RoutePath.FIND_ATHLETES);
          break;
        case UserRole.COMPANY_ADMIN:
          navigate(RoutePath.FIND_ATHLETES);
          break;
        case UserRole.UNIVERSITY:
          navigate(RoutePath.UNIVERSITY_DASHBOARD);
          break;
        case UserRole.ADMIN:
          navigate(RoutePath.ADMIN);
          break;
        default:
          navigate(RoutePath.ATHLETE_DASHBOARD);
      }
    }
  }, [userInfo, navigate, isSuccessAthleteProfile]);

  // We might make this more nuanced in the future, but for now this is good enough.
  const determineAthletePath = () => {
    if (isSuccessAthleteProfile && athleteProfile && athleteProfile.sections.length > 0) {
      return RoutePath.ATHLETE_DASHBOARD;
    }
    setIsOnboarding(true);
    return RoutePath.ATHLETE_ONBOARDING;
  };

  if (!userInfo) {
    return <AwesomeHCASpinner fullScreen />;
  }

  // if (isError) {
  //   return <Page404 />;
  // }

  return <AwesomeHCASpinner fullScreen />;
};

export default UserRoleBasedRedirect;
